import Head from 'components/head'
import Layout from 'layouts'
import ProductsPage from 'components/pages/productsPage'
import React from 'react'
import { metaTagData } from 'config'

const Products = (): React.ReactElement => {
  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <ProductsPage />
      </Layout>
    </>
  )
}

export default Products
