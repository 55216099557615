interface IHttpResponse<T> extends Response {
  parsedBody?: T
}

const http = async <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
  const response: IHttpResponse<T> = await fetch(request)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  try {
    response.parsedBody = await response.json()
    return response
  } catch (ex) {
    throw new Error(ex)
  }
}

const get = async <T>(path: string, args: RequestInit = { method: 'get' }): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args))
}

const post = async <T>(path: string, body: unknown, args: RequestInit = { method: 'post', body: JSON.stringify(body) }): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args))
}

const put = async <T>(path: string, body: unknown, args: RequestInit = { method: 'put', body: JSON.stringify(body) }): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args))
}
export default { get, post, put }
