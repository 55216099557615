import BreadcrumbBar from 'components/BreadcrumbBar'
import GitRepoTable from './gitRepoTable'
import HaveProject from 'components/haveProject'
import Masthead from 'components/mastHead/index.tsx'
import PageContent from 'components/pageContent'
import React from 'react'
import img from './masthead.jpg'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'
import tripstreamerImage from './tripstreamerImage.jpg'

const ThumbnailWide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  ${styleConfig.breakPoints.lg} {
    flex-direction: row;
    max-width: none;
  }
`

const ThumbnailImage = styled.div`
    display:block;
    flex-grow: 1;
    background-size:100% 250px;
    background-position: right;
    background-repeat:no-repeat;
    background-position: top left;
    background-image: url("${tripstreamerImage}");
    max-height: 250px;
    height:250px;
    flex-basis: 600px;
    ${styleConfig.breakPoints.sm} {
      background-size:cover;
      max-width:100%;
      background-position: center;
  }
    ${styleConfig.breakPoints.lg} {
      background-size:100% 100%;
      max-height: 100%;
      height:auto;
  }
`

const ProductsPage = (): React.ReactElement => {
  return (
    <>
      <Masthead backgroundImage={img}>
        <h1 className="lg:text-6xl text-5xl text-center">Products</h1>
      </Masthead>
      <BreadcrumbBar>
        <BreadcrumbBar.Content>
          <li>
            <a href="#apps">Applications</a>
          </li>
          <li>
            <a href="#opensource">Open Source</a>
          </li>
        </BreadcrumbBar.Content>
      </BreadcrumbBar>
      <div className="bg-gray-200 flex flex-col flex-grow">
        <PageContent className="lg:mb-20 my-3 p-4 items-center">
          <h2 id="apps" className="font-thin text-center sm:text-5xl lg:my-5">
            Applications
          </h2>
          <ThumbnailWide className="shadow-lg bg-white border border-gray-300 rounded-lg mt-4 mx-6">
            <ThumbnailImage />
            <div className="flex flex-row flex-grow p-5 lg:p-10 justify-center items-center">
              <div>
                <h4>Tripstreamer</h4>
                <p className="lg:py-6">We&apos;re currently building what we hope will be your default travel application. Please check back for updates.</p>
                <span className="bg-blue-400 rounded-full font-bold text-white px-5 py-2 text-base ">Coming soon</span>
              </div>
            </div>
          </ThumbnailWide>
        </PageContent>
      </div>
      <div className="bg-gray-100 flex flex-col flex-grow">
        <PageContent className="mb-20 my-3 p-4 items-center">
          <h2 id="opensource" className="font-thin text-center lg:text-5xl my-2 lg:my-10">
            Open Source
          </h2>
          <p>
            Some of our projects are available to the open source community. They are listed here and on GitHub at{' '}
            <a className="hover:text-blue-800 underline" target="_blank" rel="noopener noreferrer" href="https://github.com/rtorcato">
              https://github.com/rtorcato
            </a>
          </p>
          <GitRepoTable username="rtorcato" className="mb-10 mt-10" />
        </PageContent>
      </div>
      <HaveProject />
    </>
  )
}

export default ProductsPage
