import FetchService from 'services/FetchService'
import React from 'react'
import { SiteIcons } from 'components/icons/SiteIcon'

interface IProps {
  username: string
  className?: string
}
interface IGitHubResponse {
  fork: boolean
  name: string
  full_name: string
  html_url: string
  description: string
}

const GitRepoTable = (props: IProps): React.ReactElement => {
  const [repos, setRepos] = React.useState<IGitHubResponse[]>([])
  React.useEffect(() => {
    async function fetchData(): Promise<void> {
      const result = await FetchService.get<IGitHubResponse[]>(`https://api.github.com/users/${props.username}/repos`)
      if (result.parsedBody) {
        const filtered = result.parsedBody.filter((repo) => repo.fork === false)
        setRepos(filtered)
      }
    }
    fetchData()
  }, [props.username])
  /*
function sortByNumberOfWatchers(repos) {
		repos.sort(function (a, b) {
			return b.watchers - a.watchers;
		});
	}
			$(repos).each(function () {
				if (this.fork == false) {
					target.append('<tr><td style="text-align: center;"><i class="fa fa-github fa-2x" aria-hidden="true"></i></td><td><b><b>' + this.name + '</b><br/><small><a href="' + this.html_url + '" target="_blank">' + this.html_url + '</a></small></td><td>' + this.description + '</td></tr>');
        }
        */
  return (
    <>
      <div className={props.className + ' w-full'}>
        <div className="align-middle bg-white inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-900  tracking-wider">Name</th>
                <th className="hidden lg:table-cell whitespace-pre-wrap w-10 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-900  tracking-wider">
                  Title
                </th>
                <th className="hidden lg:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-900  tracking-wider ">Status</th>
                <th className="hidden lg:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
              </tr>
            </thead>
            <tbody className="bg-white text-gray-900 text-base leading-5 w-full">
              {repos &&
                repos.map((repo, key) => {
                  return (
                    <tr key={key} className={`text-gray-700 ${key % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                      <td className="px-6 text-gray-700 py-4  border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <SiteIcons.Github />
                          </div>
                          <div className="ml-4">
                            <div className="text-base leading-5 font-medium text-gray-900">
                              <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                                {repo.name}
                              </a>
                            </div>
                            <div className="text-base leading-5 text-gray-500">{repo.full_name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell whitespace-pre-wrap w-full px-6 py-4 border-b border-gray-200">{repo.description}</td>
                      <td className="hidden lg:table-cell px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-500 text-white">Active</span>
                      </td>
                      <td className="hidden lg:table-cell px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-base leading-5 ">
                        <a className="text-blue-600 hover:text-blue-900" href={repo.html_url} target="_blank" rel="noopener noreferrer">
                          View <SiteIcons.ArrowRight size="30" />
                        </a>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/*<pre>{JSON.stringify(repos, null, 2)}</pre>*/}
    </>
  )
}

export default GitRepoTable
